import { createContext } from "react";
import { ProjectResponseData } from "../api/projects";

interface Context {
  projectId?: string;
  projectData: ProjectResponseData;
  organization: { id: string; name: string };
  isLoading: boolean;
}

const ProjectDashboardContext = createContext<Partial<Context>>({});

export default ProjectDashboardContext;
